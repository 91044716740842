<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              depressed
              icon
              :to="{
                name : Names.R_META_SEARCH
              }"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Редактирование метаинформации
            </VToolbarTitle>
          </VToolbar>

          <VCardText>
            <VAlert
              colored-border
              border="left"
              type="info"
              elevation="1"
            >
              <span class="font-weight-bold">Перед отправкой:</span> убедитесь, что Вы отправляете валидный JSON
            </VAlert>
            <VDivider class="my-4" />
            <VForm>
              <VRow>
                <VCol cols="6">
                  <VTextarea
                    v-model="meta"
                    name="meta"
                    label="Мета JSON"
                  />
                </VCol>
                <VCol cols="6">
                  <VExpansionPanels
                    accordion
                    flat
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader class="font-weight-bold">
                        Пример заполнения полей
                      </VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <code
                          class="elevation-0 d-block"
                          style="white-space: pre"
                          v-text="example"
                        />
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>

          <VCardActions>
            <VBtn
              depressed
              color="primary"
              @click="saveMeta"
            >
              Сохранить
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'MetaEdit',
  inject: ['Names'],
  data() {
    return {
      meta: '',
      example: {
        dob: '1975-12-31',
        gender: 'male/female/other',
        name: {
          first: 'John',
          middle: 'Doe',
          last: 'Ivanovich',
        },
      },
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    serviceId() {
      return this.$route.params.serviceId;
    },
  },
  created() {
    this.findMeta();
  },
  methods: {
    findMeta() {
      const payload = {
        id: this.userId,
        serviceId: this.serviceId,
      };

      this.$di.api.Meta
        .getMeta(payload)
        .then((response) => {
          try {
            this.meta = response.meta;
          } catch (e) {
            this.$di.notify.snackError('Не удалось распарсить мету');
          }
        })
        .catch(this.$di.notify.errorHandler);
    },
    saveMeta() {
      const payload = {
        assign: {
          id: this.userId,
          serviceId: this.serviceId,
          meta: this.meta,
        },
      };

      this.$di.api.Meta
        .setMeta(payload)
        .then(() => {
          this.$di.notify.snackSuccess('Сохранено успешно');
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
